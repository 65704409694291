<template>
  <ListUsers />
</template>


<script>
  import ListUsers from '../../components/utilisateurs/Liste';
  
  export default {
    name: 'UserListContainer',
    components: {
      ListUsers,
    },
    data() {
      return {};
    },
    computed: {
    },
    created() {
    },
    methods: {
    },
  };
</script>
